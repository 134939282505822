@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text-gray-900 {
  position: relative;
}

.text-gray-900::before,
.text-gray-900::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 4px; /* Adjust the thickness of the line */
  background-color: #28405b; /* The desired color */
  transition: all 0.3s ease; /* Smooth transition */
  opacity: 0; /* Initially hidden */
}

.text-gray-900::before {
  top: 0; /* Position the line above the text */
}

.text-gray-900::after {
  bottom: 0; /* Position the line below the text */
}

.text-gray-900:hover::before,
.text-gray-900:hover::after {
  opacity: 1; /* Show lines on hover */
}
